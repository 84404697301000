import React from "react";
import { connect, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { PrismicRichText } from "@prismicio/react";
import { PrivacyPolicyQuery } from "../../prismic/staticQueries";
import Layout from "../../layout";
import { Subscription, BreadCrumbBanner } from "../../components/common";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CountUp from "react-countup";
import banner_image from "../../images/siteImages/privacypolicy/AML-Policy-Banner.jpg";
import PaymentSlider from "./PaymentSlider";


const AMLPolicy = () => {
  const language = useSelector((state) => state.language);
  console.log("language", language);
  //  const privacyPolicyData = PrivacyPolicyQuery(language);
  // console.log(".....", privacyPolicyData);
  // // const { seo_page_title, seo_page_description, seo_page_keywords } =
  // //   privacyPolicyData;
  // const {
  //   privacy_policy_heading,
  //   privacy_policy_heading1,
  //   privacy_policy_heading2,
  //   privacy_policy_heading3,
  //   privacy_policy_heading4,
  //   privacy_policy_heading5,
  //   privacy_policy_heading6,
  //   privacy_policy_heading7,
  //   privacy_policy_body,
  //   privacy_policy_body1,
  //   privacy_policy_body2,
  //   privacy_policy_body3,
  //   privacy_policy_body4,
  //   privacy_policy_body5,
  //   privacy_policy_body6,
  //   privacy_policy_body7,
  //   privacy_policy_body8,
  //   privacy_policy_body9,
  //   privacy_policy_body10,
  //   privacy_policy_body11,
  //   privacy_policy_body12,
  //   privacy_policy_body13,
  //   privacy_policy_body14,
  //   privacy_policy_body15,
  //   privacy_policy_body16,
  //   privacy_policy_body17,
  //   privacy_policy_body18,
  //   privacy_policy_body19,
  //   privacy_policy_body20,
  //   privacy_policy_body21,
  //   privacy_policy_body22,
  //   privacy_policy_body23,
  //   privacy_policy_body24,
  //   privacy_policy_body25,
  //   privacy_policy_body26,
  //   privacy_policy_body27,
  //   privacy_policy_list,
  //   privacy_policy_list1,
  //   privacy_policy_list2,
  //   privacy_policy_list3,
  //   privacy_policy_list4,
  //   privacy_policy_list5,
  //   privacy_policy_list6,
  //   privacy_policy_list7,
  //   privacy_policy_list8,
  //   privacy_policy_list9,
  //   privacy_policy_list10,
  //   privacy_policy_list11,
  //   privacy_policy_list12,
  // } = privacyPolicyData;

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (

    <Layout >
      <BreadCrumbBanner banner_image={banner_image} />
      <section className="sectionbg p-top-100 p-bottom-110 faqs">
        <Container className="">
          <Row>
            <Col lg={12}>
              <h4 className="pt-5 my-3 text-bold-md text-black">Scope of Policy</h4>
              <p className="text-gray my-3">This policy applies to all Rox Capitals officers, employees, appointed producers and products and services offered by Rox Capitals. All business units and locations within Rox Capitals will cooperate to create a cohesive effort in the fight against money laundering. Each business unit and location has implemented risk-based procedures reasonably expected to prevent, detect and cause the reporting of transactions. All efforts exerted will be documented and retained. The AML Compliance Committee is responsible for initiating Suspicious Activity Reports (“SARs”) or other required reporting to the appropriate law enforcement or regulatory agencies. Any contacts by law enforcement or regulatory agencies related to the Policy shall be directed to the AML Compliance Committee.</p>
              <p className="text-gray my-3">The committee shall :</p>
              <ul className="privacy-list">
                <li className="text-gray my-3">Receive internal reports of (suspicions of) money laundering Investigate reports of suspicious events.</li>
                <li className="text-gray my-3">Make reports of relevant suspicious events to the relevant authorities.</li>
                <li className="text-gray my-3">Ensure the adequacy of arrangements made for the awareness and training of staff and advisers</li>
                <li className="text-gray my-3">Report at least annually to the firm's governing body on the operation and effectiveness of the firm's systems and controls.</li>
                <li className="text-gray my-3">Monitor the day-to-day operation of anti-money laundering policies in relation to: the development of new products; the taking on of new customers; and changes in the firm's business profile.</li>
              </ul>
              <h4 className="pt-5 my-3 text-bold-md text-black">Policy</h4>
              <p className="text-gray my-3">It is the policy of Rox Capitals to actively pursue the prevention of money laundering and any activity that facilitates money laundering or the funding of terrorist or criminal activities. Rox Capitals is committed to AML compliance in accordance with applicable law and requires its officers, employees and appointed producers to adhere to these standards in preventing the use of its products and services for money laundering purposes.</p>
              <p className="text-gray my-3">For the purposes of the Policy, money laundering is generally defined as engaging in acts designed to conceal or disguise the true origins of criminally derived proceeds so that the unlawful proceeds appear to have been derived from legitimate origins or constitute legitimate assets.</p>
              <h4 className="pt-5 my-3 text-bold-md text-black">What is Money Laundering?</h4>
              <p className="text-gray my-3">Money laundering is the process by which criminally obtained money or other assets (criminal property) are exchanged for “clean” money or other assets with no obvious link to their criminal origins.</p>
              <p className="text-gray my-3">Criminal property may take any form, including money or money's worth, securities, tangible property and intangible property. It also covers money, however come by, which is used to fund terrorism.</p>
              <h4 className="pt-5 my-3 text-bold-md text-black">Money laundering activity includes :</h4>
              <ul className="privacy-list">
                <li className="text-gray my-3">Acquiring, using or possessing criminal property.</li>
                <li className="text-gray my-3">Handling the proceeds of crimes such as theft, fraud and tax evasion.</li>
                <li className="text-gray my-3">Being knowingly involved in any way with criminal or terrorist property.</li>
                <li className="text-gray my-3">Entering into arrangements to facilitate laundering criminal or terrorist property.</li>
                <li className="text-gray my-3">Investing the proceeds of crimes in other financial products.</li>
                <li className="text-gray my-3">Investing the proceeds of crimes through the acquisition of property/assets.</li>
                <li className="text-gray my-3">Transferring criminal property.</li>
              </ul>
              <p className="text-gray my-3">There is no single stage of money laundering; methods can range from the purchase and resale of luxury items such as a car or jewellery to passing money through a complex web of legitimate operations. Usually the starting point will be cash but it is important to appreciate that money laundering is defined in terms of criminal property. This can be property in any conceivable legal form, whether money, rights, real estate or any other benefit, if you know or suspect that it was obtained, either directly or indirectly, as a result of criminal activity and you do not speak up then you too are taking a part in the process.</p>
              <p className="text-gray my-3">The money laundering process follows three stages :</p>
              <ul className="privacy-list">
                <li className="text-gray my-3">1. Placement<br></br>
                  Disposal of the initial proceeds derived from illegal activity e.g. into a bank account.
                </li>
                <li className="text-gray my-3">2. Layering<br></br>
                  The money is moved through the system in a series of financial transactions in order to disguise the origin of the cash with the purpose of giving it the appearance of legitimacy.
                </li>
                <li className="text-gray my-3">3. Integration<br></br>
                  Criminals are free to use the money as they choose once it has been removed from the system as apparently “clean” funds.
                </li>
              </ul>
              <h4 className="pt-5 my-3 text-bold-md text-black">What is Counter Terrorist Financing (CTF)?</h4>
              <p className="text-gray my-3">Terrorist financing is the process of legitimate businesses and individuals that may choose to provide funding to resource terrorist activities or organisations for ideological, political or other reasons. Firms must therefore ensure that: (i) customers are not terrorist organisations themselves; and (ii) they are not providing the means through which terrorist organisations are being funded.</p>
              <p className="text-gray my-3">Terrorist financing may not involve the proceeds of criminal conduct, but rather an attempt to conceal the origin or intended use of the funds, which will later be used for criminal purposes.</p>
              <h4 className="pt-5 my-3 text-bold-md text-black">Risk Based Approach</h4>
              <p className="text-gray my-3">The level of due diligence required when considering anti-money laundering procedures within the firm, it should take a risk-based approach. This means the amount of resources spent in conducting due diligence in any one relationship that is subject risk should be in proportion to the magnitude of the risk that is posed by that relationship.</p>
              <p className="text-gray my-3">These can be broken down into the following areas.</p>
              <h4 className="pt-5 my-3 text-bold-md text-black">Customer Risk</h4>
              <p className="text-gray my-3">Different customer profiles have different levels of risks attached to them. A basic Know your Customer (KYC) check can establish the risk posed by a customer. For example, near-retired individuals making small, regular contributions to a savings account in line with their financial details poses less of a risk than middle-aged individuals making ad-hoc payments of ever-changing sizes into a savings account that does not fit into the profile of the customers' standing financial data. The intensity of the due diligence conducted on the latter would be higher than that carried out on the former as the potential threat of money laundering in the second case would be perceived as being greater. Corporate structures can be used as examples of customers that could carry a higher risk profile than the one just seen, as these can be used by criminals to introduce layers within transactions to hide the source of the funds, and like that, clients can be categorised into different risk bands.</p>
              <h4 className="pt-5 my-3 text-bold-md text-black">Product Risk</h4>
              <p className="text-gray my-3">This is the risk posed by the product or service itself. The product risk is driven by its functionality as a money laundering tool. The Joint Money Laundering Steering Group has categorised the products with which Firms typically deal into three risk bands - reduced, intermediate and increased. Typically, pure protection contracts are categorised as reduced risk and investments in unit trusts as increased risk. Additionally, a factor that will contribute to the classification of the risk category is sales process associated with the product. If the transaction in the product takes place on an advisory basis as a result of a KYC, this will carry less risk than an execution only transaction, whereby you know significantly less about the customer.</p>
              <h4 className="pt-5 my-3 text-bold-md text-black">Country Risk</h4>
              <p className="text-gray my-3">The geographic location of the client or origin of the business activity has a risk associated with it, this stems from the fact that countries around the globe have different levels of risk attached to them.</p>
              <p className="text-gray my-3">A firm would determine the extent of their due diligence measure required initially and on an ongoing basis using the above four risk areas.</p>
              <h4 className="pt-5 my-3 text-bold-md text-black">Customer identification program</h4>
              <p className="text-gray my-3">Rox Capitals has adopted a Customer Identification Program (CIP). Rox Capitals will provide notice that they will seek identification information; collect certain minimum customer identification information from each customer, record such information and the verification methods and results.</p>
              <h4 className="pt-5 my-3 text-bold-md text-black">Notice to customers</h4>
              <p className="text-gray my-3">Rox Capitals will provide notice to customers that it is requesting information from them to verify their identities, as required by applicable law.</p>
              <h4 className="pt-5 my-3 text-bold-md text-black">Know your customer</h4>
              <p className="text-gray my-3">When a business relationship is formed, in order to establish what might constitute normal activity later in the relationship, it is necessary for the company to ascertain the nature of the business a client expects to conduct. Once an on-going business relationship has been established, any regular business undertaken for that customer can be assessed against the expected pattern of activity of the customer. Any unexplained activity can then be examined to determine whether there is a suspicion of money laundering or terrorist financing. Information regarding a client's income, occupation, source of wealth, trading habits and the economic purpose of any transaction is typically gathered as part of the provision of advice. At the start of the relationship personal information is also obtained, such as, nationality, date of birth, and residential address. These pieces of information should also be considered in respect to the risk of financial crime (including AML and CTF). For high risk transactions, it might be appropriate to seek verification of the information the client has provided.</p>
              <h4 className="pt-5 my-3 text-bold-md text-black">Source of Funds</h4>
              <p className="text-gray my-3">When a transaction takes place, the source of funds, i.e. how the payment is to be made, from where and by who, must always be ascertained and recorded in the client file (this would usually be achieved through retaining a copy of the cheque or direct debit mandate).</p>
              <h4 className="pt-5 my-3 text-bold-md text-black">Identification</h4>
              <p className="text-gray my-3">The standard identification requirement for customers who are private individuals are generally governed by the circumstances relating to the customer and the product type that is being dealt in, i.e. the level of risk attributed to the product whether it is a reduced risk, intermediate risk or an increased risk product. Taking that into account for reduced risk and intermediate risk products the following pieces of information are required as a standard for identification purposes :</p>
              <ul className="privacy-list">
                <li className="text-gray my-3">The customer's Full Name (surname, name and patronymic name).</li>
                <li className="text-gray my-3">Their Residential or Business Address.</li>
                <li className="text-gray my-3">Registration and financial e-mail.</li>
                <li className="text-gray my-3">Telephone number.</li>
              </ul>
              <h4 className="pt-5 my-3 text-bold-md text-black">Verification</h4>
              <p className="text-gray my-3">Verification of the information obtained must be based on reliable and independent sources - which might either be documents produced by the customer, or electronically by the firm, or by a combination of both. Where business is conducted face-to-face, firms should see originals of any documents involved in the verification. If documentary evidence of an individual's identity is to provide a high level of confidence, it will typically have been issued by a government department or agency, or by a court, because there is a greater likelihood that the authorities will have checked the existence and characteristics of the persons concerned. In cases where such documentary evidence of identity may not be available to an individual, other evidence of identity may give the firm reasonable confidence in the customer's identity, although the firm should weigh these against the risks involved.</p>
              <p className="text-gray my-3">If the identity is to be verified from documents, this should be based on:</p>
              <p className="text-gray my-3">Either a government issued document which incorporates:</p>
              <ul className="privacy-list">
                <li className="text-gray my-3">The customer's full name, and</li>
                <li className="text-gray my-3">Their residential address</li>
              </ul>
              <p className="text-gray my-3">Photographic Government Issued Identity Documents</p>
              <ul className="privacy-list">
                <li className="text-gray my-3">Valid passport</li>
                <li className="text-gray my-3">National Identity card</li>
              </ul>
              <p className="text-gray my-3">Alternatively, this can be done by a non-photographic government issued document which incorporates the customer's full name, supported by a second document, which incorporates:</p>
              <ul className="privacy-list">
                <li className="text-gray my-3">The Customer's full Name (surname, name, and patronymic name).</li>
                <li className="text-gray my-3">Their Residential or Business address.</li>
                <li className="text-gray my-3">Registration and Financial e-mail.</li>
                <li className="text-gray my-3">Contact Number.</li>
              </ul>
              <p className="text-gray my-3">Rox Capitals does not limit the time for the Client to submit their verification documents, however submitting them is an obligatory requirement for the Client to withdraw their funds.</p>
              <p className="text-gray my-3">Rox Capitals undertakes to review the submitted documents within 24 hours from the date of receiving them.</p>
              <h4 className="pt-5 my-3 text-bold-md text-black">Monitoring and reporting</h4>
              <p className="text-gray my-3">Transaction based monitoring will occur within the appropriate business units of Rox Capitals. Monitoring of specific transactions will include but is not limited to transactions aggregating $5,000 or more and those with respect to which Rox Capitals has a reason to suspect suspicious activity. All reports will be documented.</p>
              <h4 className="pt-5 my-3 text-bold-md text-black">Suspicious activity</h4>
              <p className="text-gray my-3">There are signs of suspicious activity that suggest money laundering. These are commonly referred to as “red flags.” If a red flag is detected, additional due diligence will be performed before proceeding with the transaction. If a reasonable explanation is not determined, the suspicious activity shall be reported to the AML Compliance Committee.</p>
              <p className="text-gray my-3">Examples of red flags are :</p>
              <ul className="privacy-list">
                <li className="text-gray my-3">The customer exhibits unusual concern regarding the firm's compliance with government reporting requirements and the firm's AML policies, particularly with respect to his or her identity, type of business and assets, or is reluctant or refuses to reveal any information concerning business activities, or furnishes unusual or suspect identification or business documents.</li>
                <li className="text-gray my-3">The customer wishes to engage in transactions that lack business sense or apparent investment strategy, or are inconsistent with the customer's stated business strategy.</li>
                <li className="text-gray my-3">The information provided by the customer that identifies a legitimate source for funds is false, misleading, or substantially incorrect.</li>
                <li className="text-gray my-3">Upon request, the customer refuses to identify or fails to indicate any legitimate source for his or her funds and other assets.</li>
                <li className="text-gray my-3">The customer (or a person publicly associated with the customer) has a questionable background or is the subject of news reports indicating possible criminal, civil, or regulatory violations.</li>
                <li className="text-gray my-3">The customer exhibits a lack of concern regarding risks, commissions, or other transaction costs.</li>
                <li className="text-gray my-3">The customer appears to be acting as an agent for an undisclosed principal, but declines or is reluctant, without legitimate commercial reasons, to provide information or is otherwise evasive regarding that person or entity.</li>
                <li className="text-gray my-3">The customer has difficulty describing the nature of his or her business or lacks general knowledge of his or her industry.</li>
                <li className="text-gray my-3">The customer attempts to make frequent or large deposits of currency, insists on dealing only in cash equivalents, or asks for exemptions from the firm's policies relating to the deposit of cash and cash equivalents.</li>
                <li className="text-gray my-3">For no apparent reason, the customer has multiple accounts under a single name or multiple names, with a large number of inter-account or third-party transfers.</li>
                <li className="text-gray my-3">The customer's account has unexplained or sudden extensive activity, especially in accounts that had little or no previous activity.</li>
                <li className="text-gray my-3">The customer's account has a large number of wire transfers to unrelated third parties inconsistent with the customer's legitimate business purpose.</li>
                <li className="text-gray my-3">The customer's account has wire transfers that have no apparent business purpose to or from a country identified as money laundering risk or a bank secrecy haven.</li>
                <li className="text-gray my-3">The customer's account indicates large or frequent wire transfers, immediately withdrawn by check or debit card without any apparent business purpose.</li>
                <li className="text-gray my-3">The customer makes a funds deposit followed by an immediate request that the money be wired out or transferred to a third party, or to another firm, without any apparent business purpose.</li>
                <li className="text-gray my-3">The customer makes a funds deposit for the purpose of purchasing a long-term investment followed shortly thereafter by a request to liquidate the position and transfer of the proceeds out of the account.</li>
                <li className="text-gray my-3">The customer requests that a transaction be processed in such a manner to avoid the firm's normal documentation requirements.</li>
              </ul>
              <h4 className="pt-5 my-3 text-bold-md text-black">Suspicious scenarios</h4>
              <p className="text-gray my-3">Issues which should lead you to have cause for suspicion would include :</p>
              <ul className="privacy-list">
                <li className="text-gray my-3">Clients who are reluctant to provide proof of identity</li>
                <li className="text-gray my-3">The customer wishes to engage in transactions that lack business sense or apparent investment strategy, or are inconsistent with the customer's stated business strategy.</li>
                <li className="text-gray my-3">Clients who place undue reliance on an introducer (they may be hiding behind the introducer to avoid giving you a true picture of their identity or business);</li>
                <li className="text-gray my-3">Requests for cash related business, for example questions about whether investments can be made in cash, suggestions that funds might be available in cash for investment;</li>
                <li className="text-gray my-3">Where the source of funds for investment is unclear;</li>
                <li className="text-gray my-3">Where the magnitude of the available funds appears inconsistent with the client's other circumstances (i.e. the source of wealth is unclear). Examples might be students or young people with large amounts to invest.</li>
                <li className="text-gray my-3">Where the transaction doesn't appear rational in the context of the customer's business or personal activities. Particular care should be taken in this area if the client changes their method of dealing with you without reasonable explanation;</li>
                <li className="text-gray my-3">Where the pattern of transactions changes;</li>
                <li className="text-gray my-3">Where a client who is undertaking transactions that are international in nature does not appear to have any good reason to be conducting business with the countries involved (e.g. why do they hold monies in the particular country that the funds are going to or from? Do their circumstances suggest that it would be reasonable for them to hold funds in such countries?);</li>
                <li className="text-gray my-3">Clients who are unwilling to provide you with normal personal or financial information, for no apparent or rational reason. (Care should be taken not to include all distance relationships as suspicious, because most will be for genuine reasons. Suspicions will ordinarily be based upon cumulative as opposed to stand alone issues)</li>
              </ul>
              <p className="text-gray my-3">A money launderer is likely to provide persuasive arguments about the reasons for their transactions. Those should be questioned to decide whether a transaction is suspicious.</p>
              <h4 className="pt-5 my-3 text-bold-md text-black">Reporting a Suspicion</h4>
              <p className="text-gray my-3">Where, for whatever reason, we suspect that a client, or anybody for whom they are acting, may be undertaking (or attempting to undertake) a transaction involving the proceeds of any crime it must be reported as soon as practicably possible and in writing. Internal reports must be made regardless of whether any business was, or is intended to be, actually written.</p>
              <h4 className="pt-5 my-3 text-bold-md text-black">Investigation</h4>
              <p className="text-gray my-3">Upon notification to the AML Compliance Committee an investigation will be commenced to determine if a report should be made to the appropriate law enforcement or regulatory agencies. The investigation will include, but not necessarily be limited to, review of all available information, such as payment history, birth dates, and address. If the results of the investigation warrant, a recommendation will be made to the AML Compliance Committee to file the SAR with the appropriate law enforcement or regulatory agency. The AML Compliance Committee is responsible for any notice or filing with law enforcement or regulatory agency.</p>
              <p className="text-gray my-3">Investigation results will not be disclosed or discussed with anyone other than those who have a legitimate need to know. Under no circumstances shall any officer, employee or appointed agent disclose or discuss any AML concern, investigation, notice or SAR filing with the person or persons </p>
              <p className="text-gray my-3">subject of such, or any other person, including members of the officer's, employee's or appointed agent's family.</p>
              <h4 className="pt-5 my-3 text-bold-md text-black">Freezing of Accounts</h4>
              <p className="text-gray my-3">Where we know that the funds in an account derive from criminal activity, or that they arise from fraudulent instructions, the account must be frozen. Where it is believed that the account holder may be involved in the fraudulent activity that is being reported, then the account may need to be frozen.</p>
            </Col>
          </Row>
        </Container>
      </section>

      <PaymentSlider/>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(AMLPolicy);
